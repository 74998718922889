//hooks
import { useEffect, useContext, useState } from "react"

// css
import styles from "./Certification.module.css"

//icons
import { CircleGauge, ExternalLink, HardDrive, Phone, RefreshCcw, Server, Tv, Wifi } from "lucide-react"
import serverWithVoip from "../../../../assets/svgs/server-with-telphone.svg"
import { InterfacesHguSvg } from "../../../../assets/cpes/InterfacesHguSvg"

// components
import Percent from "../../../../components/porcent_vertical/Percent"
import { EntryRj45 } from "../../../../assets/cpes/EntryRj45"
import Cards from "../Cards/Cards"
import Button from '@mui/material/Button';


//api
import { useParams } from "react-router-dom"

//context
import { GetCertContext } from "../../../../hooks/useGetCertContext"

//external
import { Box, CircularProgress } from "@mui/material"
import { DhcpTable } from "../../../../interfaces/DeviceMap"
import DialogCpe from "./InfoCpe/DialogCpe"

type CpeInfo = {
    status: string;
    speed: string;
    mac: string;
    port: string;
    details: DhcpTable[];
}

const Certfication = () => {

    const { cert, getCert, loading, dhcpTable } = useContext(GetCertContext)

    const { value } = useParams()

    const [dataCpe, setDataCpe] = useState<CpeInfo[] | undefined>()

    const handleCert = async () => {
        getCert(value)
    }

    useEffect(() => {
        
        
    }, [dhcpTable])

    useEffect(() => {
        let dataCpe: CpeInfo[] = [
            {
                status: cert?.cert_lan.eth0_status ? "up" : "down",
                speed: cert?.cert_lan.eth0_bandwidth ? cert?.cert_lan.eth0_bandwidth : "",
                mac: "",
                port: "ETH1",
                details: [],
            },
            {
                status: cert?.cert_lan.eth1_status ? "up" : "down",
                speed: cert?.cert_lan.eth1_bandwidth ? cert?.cert_lan.eth1_bandwidth : "",
                mac: "",
                port: "ETH2",
                details: [],
            },
            {
                status: cert?.cert_lan.eth2_status ? "up" : "down",
                speed: cert?.cert_lan.eth2_bandwidth ? cert?.cert_lan.eth2_bandwidth : "",
                mac: "",
                port: "ETH3",
                details: [],
            },
            {
                status: cert?.cert_lan.eth3_status ? "up" : "down",
                speed: cert?.cert_lan.eth3_bandwidth ? cert?.cert_lan.eth3_bandwidth : "",
                mac: "",
                port: "ETH4",
                details: []
            },
        ]

        if (dhcpTable) {
            dhcpTable.forEach(obj => {
                if (obj.ethernet_port === "eth1") {
                    dataCpe[0].details.push(obj)
                } else if (obj.ethernet_port === "eth2") {
                    dataCpe[1].details.push(obj)
                } else if (obj.ethernet_port === "eth3") {
                    dataCpe[2].details.push(obj)
                } else if (obj.ethernet_port === "eth4") {
                    dataCpe[3].details.push(obj)
                }
            })
        }

        setDataCpe(dataCpe)
    }, [cert])


    return (
        <div className={styles.main}>
            <>
                {!loading && <>
                    {cert && <>
                        <section className={styles.left}>
                            <header>
                                <span className={styles.card_percent}>
                                    <h1 style={{ fontSize: ".8rem" }}>Score</h1>
                                    <h1>{cert.summary_status_score}</h1>
                                    <RefreshCcw color="#fff" cursor="pointer" onClick={handleCert} />
                                </span>
                                <span className={styles.bars_percent}>
                                    <Percent
                                        passed={cert.dadosAtividade.passed}
                                        failed={cert.dadosAtividade.failed}
                                    />
                                </span>
                            </header>
                            <article className={styles.topology_and_services}>
                                <div className={styles.services}>
                                    <span className={styles.header}>
                                        <h1>Serviços</h1>
                                        {cert.dadosAtividade.connection_state.toUpperCase().includes("DISCONNECTED") ?
                                            <>
                                                <div style={{ backgroundColor: "#FF0000" }}>
                                                    <p className={styles.p_circle}></p>
                                                    <p>{cert.dadosAtividade.connection_state.toUpperCase()}</p>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div>
                                                    <p className={styles.p_circle}></p>
                                                    <p>{cert.dadosAtividade.connection_state.toUpperCase()}</p>
                                                </div>
                                            </>
                                        }
                                    </span>
                                    <hr />
                                    <div className={styles.span_services}>
                                        <span
                                            className={
                                                cert.primary_diagnosis.internet_service === "" ?
                                                    styles.icons_services_desactivated
                                                    : cert.primary_diagnosis.internet_service === "up" ?
                                                        styles.icons_services :
                                                        styles.icons_services_down
                                            }
                                        >
                                            <Wifi />
                                            <p>Internet</p>
                                        </span>
                                        <span
                                            className={
                                                cert.primary_diagnosis.iptv_service === "" ?
                                                    styles.icons_services_desactivated
                                                    : cert.primary_diagnosis.iptv_service === "up" ?
                                                        styles.icons_services :
                                                        styles.icons_services_down
                                            }
                                        >
                                            <Tv />
                                            <p>TV</p>
                                        </span>
                                        <span
                                            className={
                                                cert.primary_diagnosis.voip_status === "" ?
                                                    styles.icons_services_desactivated
                                                    : cert.primary_diagnosis.voip_status === "up" ?
                                                        styles.icons_services :
                                                        styles.icons_services_down
                                            }
                                        >
                                            <Phone />
                                            <p>VoIP</p>
                                        </span>
                                        <span
                                            className={
                                                cert.primary_diagnosis.sip_register === "" ?
                                                    styles.icons_services_desactivated
                                                    : cert.primary_diagnosis.sip_register === "up" ?
                                                        styles.icons_services :
                                                        styles.icons_services_down
                                            }
                                        >
                                            <img src={serverWithVoip} alt="" />
                                            <p>SIP</p>
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.topology}>
                                    <span className={styles.header}>
                                        <h1>Topologia</h1>
                                    </span>
                                    <hr />
                                    <div className={styles.topology_content}>
                                        <span className={styles.span_icon}>
                                            <Wifi />
                                            <p>Internet</p>
                                        </span>
                                        <span className={styles.span_info}>
                                            <p className={styles.color}>{cert.dadosAtividade.connection_state}</p>
                                            <hr />
                                            <p>{cert.dadosAtividade.internet_ipv4}</p>
                                        </span>
                                        <span className={styles.span_icon}>
                                            <Server />
                                            <p>OLT</p>
                                        </span>
                                        <span className={styles.span_info}>
                                            <p className={styles.color}>RX: {cert.primary_diagnosis.gpon_rx.toFixed(2)} dBm</p>
                                            <hr />
                                            <p>TX: {cert.primary_diagnosis.gpon_tx.toFixed(2)} dBm</p>
                                        </span>
                                        <span className={styles.span_icon}>
                                            <HardDrive />
                                            <p>HGU</p>
                                        </span>
                                    </div>
                                </div>
                            </article>
                            <div className={styles.ethenet}>
                                <span className={styles.header_eth}>
                                    <h1>Ethernet</h1>
                                    <hr />
                                </span>
                                <section className={styles.content_hgu_info}>
                                    {dataCpe &&
                                        <InterfacesHguSvg cpe={dataCpe} />
                                    }
                                    <hr style={{ margin: "0  10px", height: "9rem" }} />
                                    <div className={styles.more_hgu_nfo}>
                                        <ul className={styles.ports_info}>
                                            {dataCpe && dataCpe.map((info, index) => (
                                                <li
                                                    className={info.status === "up" ? styles.port_up : styles.port_down}
                                                    key={index}
                                                >
                                                    <EntryRj45 statusPort={info.status} />
                                                    <h1 className={info.status === "up" ? styles.h1_port_up : styles.h1_port_down}>{info.port} ({info.status})</h1>
                                                    <div>
                                                        <span>
                                                            <CircleGauge />
                                                            <h1 >{info.speed}</h1>
                                                        </span>
                                                        <p>{info.mac}</p>
                                                    </div>
                                                    {info.details.length > 0 ?
                                                        <DialogCpe title={info.port} data={info.details} />
                                                        :
                                                        <ExternalLink style={{ color: "#8080809c" }} />
                                                    }

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </section>
                            </div>
                        </section>
                        <hr />
                        <Cards />
                    </>}
                    {!cert && <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            <h1>Ops....</h1>
                            <h3>Parece que você perdeu a conexão com a casa do cliente</h3>
                            <Button
                                variant="contained"
                                endIcon={<RefreshCcw />}
                                onClick={handleCert}
                            >
                                Atualizar
                            </Button>
                        </div>
                    </>}
                </>}

                {
                    loading &&
                    <Box sx={{ display: 'flex', margin: "5em", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                }
            </>
        </div >
    )
}

export default Certfication