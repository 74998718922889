
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';

import { Wifi2G } from '../../../../interfaces/Hac';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type SeeList = {
  list: string[] | undefined
  value: number | undefined
}
  
//Aqui estou fazendo um novo dialog somente para mostrar um array dos valores de referencia
//do ipv4
export function Details({list, value}: SeeList){
  const [open, setOpen] = React.useState(false);  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {value}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Parâmetro(s) Esperados"}</DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{display: "flex", flexDirection: "column", gap: ".7rem", alignItems: "center"}}>
              {list?.map((item) =>(
                <p style={{margin: 0}}>{item}</p>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


type Props = {
    dataRow: Wifi2G | undefined
}

function createData(
  param: string | undefined,
  configured?: number | string ,
  expect?: number,
  listExpect?: string[] | undefined,
  status?: string,
  history?: string[] | number[]
) {
  return {
    param,
    configured,
    expect,
    listExpect,
    status,
    history
  };
}


function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {row && <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
            {row.history&&<>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
              </>}
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{row.param}</p>
          <p></p>
        </TableCell>
        <TableCell align="center">{typeof row.configured === "object" || row.configured === null ? "No Data" : row.configured}</TableCell>
        <TableCell align='center'>
          {row.expect === 1 ? <>{row.listExpect}</> :
          <><Details list={row.listExpect}value={row.expect}  /></>}
        </TableCell>
        <TableCell align="center">
          <Badge>
            {row.status === "passed" ? <CheckIcon color='success' /> :row.status === "warning" ? <ErrorOutlineIcon sx={{color: "orange"}} />
            : <CloseIcon sx={{color: "red"}} /> }
          </Badge>
        </TableCell>
      </TableRow>
      {row.history&&<>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Detalhes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history?.length > 0  &&  row.history[0] !== '' ? row.history.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {historyRow}
                      </TableCell>
                    </TableRow>
                  )): <>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sem Alertas
                    </TableCell>
                </TableRow></>}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>}
      </>}
    </React.Fragment>
  );
}

export default function CollapsibleTable({dataRow}: Props) {

  const channelMode = dataRow?.channel_mode_value === true ? "auto" : "Desabilitado"
  const channelRef = dataRow?.channel_mode_ref === true ? ["auto"] : ["Desabilitado"]
  const scsRef = dataRow?.scs_value_ref === true ? ["Habilitado"] : ["Desabilitado"]
  const statusRef = dataRow?.status_value_ref === true ? ["on"] : ["off"]
  
  const roaming2G = dataRow?.status_value_ref === true ? ["on"] : ["off"]

  const ssidHide = dataRow?.ssid_hide_ref === true ? ["on"] : ["off"]

  const rows = [
    //O primeiro e o nome do parametro seguido do o tecnico confugrou depois o que era esperado
    //se o que era esperado tiver mais de um elemento ele pode receber uma lista de strings
    //ele pega o tamanho da string e mostra num botão clicavel que mostrara todos os elementos esperados
    // e por ultimo uma lista que pode ou não existir, nela terá detalhes ou alarmes se existir
    // criara um botão logo no inicio para expandir a linha

    createData(dataRow?.auth_alias, dataRow?.auth_value, dataRow?.auth_value_ref.length, dataRow?.auth_value_ref, dataRow?.auth_validation),
    createData(dataRow?.bandwidth_alias, dataRow?.bandwidth_value, dataRow?.bandwidth_value_ref.length, dataRow?.bandwidth_value_ref, dataRow?.bandwidth_validation),
    createData("Canal Conectado", dataRow?.channel, ["---"].length, ["---"], dataRow?.channel_mode_validation),
    createData(dataRow?.channel_mode_alias, channelMode, channelRef.length, channelRef, dataRow?.channel_mode_validation),

    createData("SSID do Cliente", dataRow?.ssid, ["***"].length, ["***"], dataRow?.ssid_hide_validation),

    createData(dataRow?.ssid_hide_alias, dataRow?.ssid_hide_value === true ? "on" : "off", ssidHide.length, 
      ssidHide, dataRow?.ssid_hide_validation),
    
    createData(dataRow?.status_alias, dataRow?.status_value === true ? "on" : "off", statusRef.length, 
      [statusRef.toString()], dataRow?.status_validation),

    createData(dataRow?.roaming_2g_alias, dataRow?.roaming_2g_value === true ? "on" : "off", roaming2G.length, 
      roaming2G, dataRow?.roaming_2g_validation),

    createData(dataRow?.repeater_2g_alias, dataRow?.repeater_2g_value, dataRow?.repeater_2g_value_ref.length, 
    dataRow?.repeater_2g_value_ref, dataRow?.repeater_2g_validation),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Parâmetros</TableCell>
            <TableCell align="center">Configurado</TableCell>
            <TableCell align="center">Esperado</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.param} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}