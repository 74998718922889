import React from 'react';
import SuporteSidebar from '../components/SuporteSidebar';

const Suporte = () => {
  return (
    <div>
      <SuporteSidebar />
    </div>
  );
};

export default Suporte;