// hook
import { useParams } from 'react-router-dom';

// css
import styles from "./NewHac.module.css"

// content
import BasicTabs from './content/TabNav'

// components
import Navbar from '../../components/Navbar'
import Aside from './content/Aside'

const NewHac = () => {

  const { value } = useParams()

  return (
    <div className={styles.container}>
        <p className='spacing'></p>
        <Navbar />
        <main className={styles.main}>
          <BasicTabs
            id={value}
          />
          <Aside />
        </main>
    </div>
  )
}

export default NewHac