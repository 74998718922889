import { AxiosError } from "axios";
import { IApiError } from '../interfaces/ApiError';

class ApiError extends AxiosError {
  code: string | undefined;
  message: string;
  cause: Error | undefined;
  responseData: IApiError | undefined;

  constructor({
    code,
    message,
    cause,
    responseData
  }: {
    code: string | undefined;
    message: string;
    cause?: Error | undefined;
    responseData?: IApiError | undefined;
  }) {
    super();
    this.code = code;
    this.message = message;
    this.cause = cause;
    this.responseData = responseData;
  }
}

export default ApiError;