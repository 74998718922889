import React from 'react'

import styles from "./Carroussel.module.css"

import { SummaryStatus } from "../../interfaces/Hac"
import Carousel from 'react-multi-carousel'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ListItemIcon, ListItemText } from '@mui/material';

import AlertDialogSlide from '../../pages/Internet/ViewMore';
// import AlertDialogSlideHac from './Details/ViewMore';

import negative from "../../assets/images/negative.png"
import checked from "../../assets/images/checked.png"

type statusType = {
    status: string,
    desc: string
}

  
type CardsType = {
    title: string,
    statuses: statusType[]
}

type Data = {
    carousel: CardsType[],
    id?: string
}

const Carrousel = ( data: Data) => {

  return (
    <section className={styles.caroussel}>
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className={styles.carousel}
            containerClass="container"
            dotListClass=""
            draggable={false}
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
                desktop: {
                    breakpoint: {
                    max: 3000,
                    min: 1024,
                    },
                    items: 4,
                    partialVisibilityGutter: 20,
                },
                mobile: {
                    breakpoint: {
                    max: 464,
                    min: 0,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                },
                tablet: {
                    breakpoint: {
                    max: 1024,
                    min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
                >

                {data.carousel.map((card) => (
                    <Card  variant="outlined" className={styles.card} sx={{textAlign: "center", borderRadius: "10px", minWidth: 275, margin: "0px 10px", boxShadow: "-9px 10px 15px #a9a9a92b", height: 400 }}>
                        <CardContent  className={styles.cardContent}>
                            <Typography  variant="h5" component="div" color="#676769"  sx={{fontWeight: 600}}>
                            {card.title}
                            </Typography>
                            <hr/>
                            {card.statuses.map((item) =>(
                                <Typography  component="div" style={{flexDirection: "row"}} sx={{ minWidth: 275, margin: "10px 0px", }} className={styles.line_card} >
                                    <ListItemIcon>
                                    {item.status === 'passed' || item.status === "OK" ? <img src={checked} alt="positive" /> : 
                                        <img src={negative} alt="negative" />
                                    }
                                    </ListItemIcon>
                                    <ListItemText primary={item.desc} sx={{ textAlign: "justify" }} />
                                </Typography>
                            ))}
                        </CardContent>
                        <div className={styles.cardButton}>
                            <AlertDialogSlide title={card.title} protocolo={data.id} /> {/* ou faz vaidação aqui ou envia o id pra fazer um request lá */}

                        </div>
                    </Card>
                ))}
                </Carousel>
        </section>
  )
}

export default Carrousel