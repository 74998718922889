import ApiError from './ApiError';
import { ApiSysError } from '../interfaces/ApiSysError';

type Props = {
  error: any,
  setError: (message: string) => void;
};

function handleError({ error, setError }: Props) {
  if (error instanceof ApiError) {
    if (error.status === 404) {
      setError("Resultado não encontrado.");
    } else if (error.status === 403) {
      setError("Acesso Negado");
    } else if (error.status === 400
      && (error.responseData as ApiSysError)
      && (error.responseData as ApiSysError).error.cause
      && (error.responseData as ApiSysError).error.cause[0].detail) {
      setError((error.responseData as ApiSysError).error.cause[0].detail);
    } else {
      setError("Erro na busca. Tente novamente mais tarde.");
    }
  } else {
    setError("Erro na busca. Tente novamente mais tarde.");
  }
}

export default handleError;