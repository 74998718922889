
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';

import { Wan } from '../../../../interfaces/Hac';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>; 
  },
  ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type SeeList = {
  list: string[] | undefined
  value: number | undefined
}
  
//Aqui estou fazendo um novo dialog somente para mostrar um array dos valores de referencia
//do ipv4
export function Details({list, value}: SeeList){
  const [open, setOpen] = React.useState(false);  
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {value}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Parâmetro(s) Esperados"}</DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{display: "flex", flexDirection: "column", gap: ".7rem", alignItems: "center"}}>
              {list?.map((item) =>(
                <p style={{margin: 0}}>{item}</p>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


type Props = {
    dataRow: Wan | undefined,
}

function createData(
  param: string | undefined,
  configured?: number | string | string[] ,
  expect?: number,
  listExpect?: string[] | undefined,
  status?: string,
  history?: string[] | number[]
) {
  return {
    param,
    configured,
    expect,
    listExpect,
    status,
    history
  };
}


function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      {row && <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
            {row.history&&<>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
              </>}
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{row.param}</p>
          <p></p>
        </TableCell>
        <TableCell align="center">{typeof row.configured === "object" ? "No Data" : typeof row.configured === null ? "No Data" : row.configured === "{}" ? "No Data" 
        : row.configured}</TableCell>
        <TableCell align='center'>
          {row.expect === 1 ? <>{row.listExpect}</> :
          <><Details list={row.listExpect}value={row.expect}  /></>}
        </TableCell>
        <TableCell align="center">
          <Badge>
            {row.status === "passed" ? <CheckIcon color='success' /> :row.status === "warning" ? <ErrorOutlineIcon sx={{color: "orange"}} />
            : <CloseIcon sx={{color: "red"}} /> }
          </Badge>
        </TableCell>
      </TableRow>
      {row.history&&<>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Detalhes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history?.length > 0 ? row.history.map((historyRow, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {historyRow}
                      </TableCell>
                    </TableRow>
                  )): <>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sem Alertas
                    </TableCell>
                </TableRow></>}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </>}
      </>}
    </React.Fragment>
  );
}

export default function CollapsibleTable({dataRow}: Props) {
  const dynIP = dataRow?.dyn_ip_status === true ? "Habilitado" : "Desabilitado"
  const statusIface = dataRow?.status_iface === "Connected" ? "Conectado" : "Desconectado"
  const statusIfaceRef = [dataRow?.status_iface_ref]
  const hguTx = [dataRow?.hgu_tx_value_ref]
  let hguTxValue = dataRow?.hgu_tx

  if(typeof hguTxValue !== "number" && typeof hguTxValue !== "string"){
    hguTxValue = 0
  }

  const hguRx = [dataRow?.hgu_rx_value_ref]

  const vlans: string[] | undefined = dataRow?.vlan_id_value_ref.map(numero => numero.toString());

  const rows = [
    createData(dataRow?.dyn_ip_status_alias, dynIP ,["Habilitado"].length, ["Habilitado"], dataRow?.dyn_ip_status_validation),
    createData(dataRow?.status_iface_alias, statusIface ,[dataRow?.status_iface_ref].length, [statusIfaceRef.toString()], dataRow?.status_iface_validation),
    createData(dataRow?.vlan_id_alias, dataRow?.vlan_id_value , dataRow?.vlan_id_value_ref.length, vlans, dataRow?.vlan_id_validation),
    createData(dataRow?.dns_ipv4_alias,dataRow?.dns_ipv4.toLocaleString() ,dataRow?.dns_ipv4_ref.length, dataRow?.dns_ipv4_ref, dataRow?.dns_ipv4_validation),
    createData(dataRow?.hgu_rx_alias, dataRow?.hgu_rx + " dBm" , [dataRow?.hgu_rx_value_ref].length, [hguRx.toString() + " dBm"], dataRow?.hgu_rx_validation),
    createData(dataRow?.hgu_tx_alias, hguTxValue + " dBm" , [dataRow?.hgu_tx_value_ref].length, [hguTx.toString()+ " dBm"], dataRow?.hgu_tx_validation),
    ]
  
  return (
    <TableContainer component={Paper}>
      
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Parâmetros</TableCell>
            <TableCell align="center">Configurado</TableCell>
            <TableCell align="center">Esperado</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.param} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}