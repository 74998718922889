// react
import { useEffect, useState, useContext } from 'react'

//hooks
import { useNavigate, useParams } from 'react-router-dom';

// styles
import styles from "./Search.module.css"

// external
import { Select, MenuItem, OutlinedInput, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import 'dayjs/locale/de';
import dayjs, { Dayjs } from 'dayjs';

import IconButton from '@mui/material/IconButton';

//assets
import hguImage from "../../assets/cpes/1_geral.png"
import hgu6 from "../../assets/cpes/Hgu6-Frente.png"
import { Search } from 'lucide-react';
import DateFormated from '../ReturnDate/DateFormated';
import { IpLightListData } from '../../interfaces/IPLightList';
import { useUserContext } from '../../contexts/UserContext';


enum Produtos {
  todosProdutos = 'Produtos' as any,
  remoteMagic = 'MagicTool Remoto' as any,
  certWifi = 'Certificados Wi-Fi' as any,
  certBandWidth = 'Certificados Banda Larga' as any,
}

enum Status {
  todos = 'Status' as any,
  grave = 'Grave' as any,
  alerta = 'Alerta' as any,
  moderado = 'Moderado' as any,
  leve = 'Leve' as any,
}


interface Props {
  response: IpLightListData[] | undefined;
}

const SearchList = ({ response }: Props) => {

  useEffect(() => {

  }, [response]);

  const [products, setProducts] = useState<Produtos>(Produtos.todosProdutos);
  const [status, setStatus] = useState<Status>(Status.todos);
  const [queryValue, setQueryValue] = useState<string>('');
  const [dateIsVisible, setDateIsVisible] = useState<boolean>(false)
  const [dateIsVisibleEnd, setDateIsVisibleEnd] = useState<boolean>(false)
  const [dateInit, setDateInit] = useState<Dayjs | null>(dayjs())
  const [initialDateFilter, setInitialDateFilter] = useState<string>()
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs())
  const [endDateFilter, setEndDateFilter] = useState<string>()

  const { filter, value } = useParams()
  const { user } = useUserContext();

  const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6"];

  const handleChangeDate = (newDate: Dayjs | null) => {
    if (newDate) {
      setDateInit(newDate)
      setInitialDateFilter(newDate.format("DD/MM/YYYY"))
    }
    setDateIsVisible(false)
  }


  const handleChangeDateEnd = (newDate: Dayjs | null) => {
    if (newDate) {
      setDateEnd(newDate)
      setEndDateFilter(newDate.format("DD/MM/YYYY"))
    }
    setDateIsVisibleEnd(false)
  }

  const navigate = useNavigate()

  const listFiltered = response?.filter(filtro => filtro.designador.includes(queryValue)
    || filtro.modelo.includes(queryValue)
    || filtro.serial_number.includes(queryValue)
    || filtro.product.includes(products.toString())
  )

  const handlePage = (page: string) => {
    if (filter) {
      switch (filter) {
        case "remoteMagic":
          navigate(`/servicos-produtos/hac/remoteMagic/${page}`)
          break

        case "certWifi":
          navigate(`/servicos-produtos/wi-fi/certWifi/${page}`)
          break

        case "certBandWidth":
          navigate(`/servicos-produtos/banda-larga/certBandWidth/${page}`)
          break
      }
    }
  }

  return (
    <>
      <div className={styles.container_filters}>
        <Select
          className={styles.select_filters}
          autoWidth
          value={products}
          onChange={(e) => setProducts(e.target.value as Produtos)}
          sx={{ padding: "2px" }}
        >
          <MenuItem sx={{ width: "250px" }} value={Produtos.todosProdutos}>{Produtos.todosProdutos}</MenuItem>
          {user && user.perfil !== "tecnico" ?
            <MenuItem value={Produtos.remoteMagic}>{Produtos.remoteMagic}</MenuItem>
            :
            <></>
          }
          <MenuItem sx={{ width: "250px" }} value={Produtos.certWifi}>{Produtos.certWifi}</MenuItem>
          <MenuItem sx={{ width: "250px" }} value={Produtos.certBandWidth}>{Produtos.certBandWidth}</MenuItem>
        </Select>
        <Select
          className={styles.select_filters}
          autoWidth
          value={status}
          onChange={(e) => setStatus(e.target.value as Status)}
          sx={{ padding: "2px" }}
        >
          <MenuItem sx={{ width: "250px" }} value={Status.todos}>{Status.todos}</MenuItem>
          <MenuItem sx={{ width: "250px" }} value={Status.grave}>{Status.grave}</MenuItem>
          <MenuItem sx={{ width: "250px" }} value={Status.alerta}>{Status.alerta}</MenuItem>
          <MenuItem sx={{ width: "250px" }} value={Status.moderado}>{Status.moderado}</MenuItem>
          <MenuItem sx={{ width: "250px" }} value={Status.leve}>{Status.leve}</MenuItem>
        </Select>
        <div className={styles.select_filters_date} >
          <OutlinedInput
            className={styles.select_filters}
            placeholder='Período de:'
            value={initialDateFilter}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                >
                  {
                    dateIsVisible ?
                      <span className="material-symbols-outlined" onClick={() => setDateIsVisible(false)}>
                        arrow_drop_up
                      </span>
                      :
                      <span className="material-symbols-outlined" onClick={() => setDateIsVisible(true)}>
                        arrow_drop_down
                      </span>
                  }
                </IconButton>
              </InputAdornment>
            }
          />
          {dateIsVisible &&
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={dateInit}
                onChange={(newDate) => handleChangeDate(newDate)}
                className={styles.calendar}
              />
            </LocalizationProvider>
          }
        </div>
        <div className={styles.select_filters_date} >
          <OutlinedInput
            className={styles.select_filters}
            placeholder='Até:'
            value={endDateFilter}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                >
                  {
                    dateIsVisibleEnd ?
                      <span className="material-symbols-outlined" onClick={() => setDateIsVisibleEnd(false)}>
                        arrow_drop_up
                      </span>
                      :
                      <span className="material-symbols-outlined" onClick={() => setDateIsVisibleEnd(true)}>
                        arrow_drop_down
                      </span>
                  }
                </IconButton>
              </InputAdornment>
            }
          />
          {dateIsVisibleEnd &&
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={dateEnd}
                onChange={(newDate) => handleChangeDateEnd(newDate)}
                className={styles.calendar}
              />
            </LocalizationProvider>
          }
        </div>
        <div className={styles.search_filter}>
          <OutlinedInput
            className={styles.select_filters}
            placeholder='Buscar'
          />
          <span className={styles.icon_search}>
            <Search />
          </span>
        </div>
      </div>
      {response &&
        <div className={styles.div_table}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>PRODUTO</th>
                <th>FIRMWARE</th>
                <th>MODELO</th>
                <th>DESIGNADOR</th>
                <th># SERIAL</th>
                <th>LOG</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listFiltered &&
                listFiltered.map((item, index) => (
                  <tr key={index}
                    className={styles.item_list}
                    onClick={() => handlePage(item.id ? item.id : item.serial_number)}
                  >
                    <td>
                      {wifi6List.includes(item.modelo) ?
                        <img src={hgu6} alt="HGU" /> :
                        <img src={hguImage} alt="HGU" />
                      }
                    </td>
                    <td>{item.product}</td>
                    <td className={styles.firmware}>{item.firmware}</td>
                    <td>{item.modelo}</td>
                    <td>{item.designador}</td>
                    <td>{item.serial_number}</td>
                    <td><DateFormated dateGmt={3} date={item.sent_time} /></td>
                    <td className={index % 2 == 0 ? styles.last_td : styles.last_td_red}></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      }
      {response?.length === 0 || !response ? <>
        <h1 style={{ textAlign: "center" }}>
          Parece que não foram encontrado nenhum resultado em sua pesquisa. <br />
          Tente novamente com outro dispositivo ou serviço.
        </h1>
      </>
        : !response && value === undefined &&
        <>
          <h1 style={{ textAlign: "center" }}>
            Comece fazendo a busca de algum dispositivo pelo número de serial  !
          </h1>
        </>}
    </>
  )
}

export default SearchList