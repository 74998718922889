// react
import { useEffect, useState } from "react"

//hooks
import { useParams } from "react-router-dom"

// css
import styles from './MapDisp.module.css'

//images
import FreeWifi from "../../../../assets/images/free-wifi.png"
import { RefreshCcw } from "lucide-react"

//components
import CardDisp from "./CardDisp";
import Button from '@mui/material/Button';

//interface
import { DhcpTable, Root } from "../../../../interfaces/DeviceMap";
import { MapDevices } from "../../../../services/apiHac";

//external
import { Box, CircularProgress } from "@mui/material"

type DevicMapProps = {
    certDeviceMap: Root | undefined
}

const MapDisp = ({ certDeviceMap }: DevicMapProps) => {

    const [responseRequestDevice, setResponseRequestDevice] = useState<Root | undefined>()
    const [wifi2gList, setWifi2gList] = useState<DhcpTable[]>([])
    const [wifi5gList, setWifi5gList] = useState<DhcpTable[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const { value } = useParams()

    const setCertificado = async (res: Root) => {
        let wifi2list: DhcpTable[] = []
        let wifi5list: DhcpTable[] = []
        res.values.dhcp_table.forEach(element => {
            if (element.media.includes("2.4")) {
                wifi2list.push(element)
            } else if (element.media.includes("5")) {
                wifi5list.push(element)
            }
        });

        setWifi2gList(wifi2list)
        setWifi5gList(wifi5list)
    }

    const handleCert = async () => {
        if (value) {
            setLoading(true)
            try {
                const res = await MapDevices(value)
                setResponseRequestDevice(res)

            } catch (error: any) {
                setError(error.detail)
            } finally {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (certDeviceMap) {
            setCertificado(certDeviceMap)
        } else if (responseRequestDevice) {
            setCertificado(responseRequestDevice)
        }
    }, [certDeviceMap, responseRequestDevice])

    return (
        <>
            {!loading &&
                <>
                    {certDeviceMap && <>
                        <div className={styles.container}>
                            <section>
                                <header>
                                    <h1>WiFi 2.4GHz</h1>
                                    <hr />
                                </header>
                                <CardDisp devices={wifi2gList} />
                            </section>
                            <img src={FreeWifi} alt="Router Image" />
                            <section>
                                <header>
                                    <h1>WiFi 5GHz</h1>
                                    <hr />
                                </header>
                                <CardDisp devices={wifi5gList} />
                            </section>
                        </div>
                    </>}
                    {!certDeviceMap &&
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    alignItems: "center"
                                }}
                            >
                                <h1>Ops....</h1>
                                <h3>Não encontramos informações dos dispositivos conectados, tente novamente</h3>
                                {error && <p>Motivo do erro: {error}</p>}
                                <Button
                                    variant="contained"
                                    endIcon={<RefreshCcw />}
                                    onClick={handleCert}
                                >
                                    Atualizar
                                </Button>
                            </div>
                        </>}
                </>
            }
            {loading &&
                <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>}
        </>
    )
}

export default MapDisp