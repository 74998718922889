import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Topologium } from '../../interfaces/CertWifi';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props ={
    topologia: Topologium[]
}

function createData(
    titulo: string,
    param: string | string[],
) {
    return { titulo, param};
}



export default function AlertDialogSlide({topologia}: Props) {
  const [open, setOpen] = React.useState(false);

  const rows:any[] = [
    
    ];

    if(topologia){
        topologia.map((line) => {
            // console.log("wifi 2g " + line.channel_2GHz, "wifi 5g " + line.channel_5GHz)

            rows.push(createData("Nome", line.index_name))
            rows.push(createData("WiFi 2G", line.channel_2GHz))
            rows.push(createData("WiFi 5G", line.channel_5GHz))
            rows.push(createData("Canal 2G Ocupado", line.channel_ocuppation_2GHz))
            rows.push(createData("Canal 5G Ocupado", line.channel_ocuppation_5GHz))
            rows.push(createData("Tipo de conexão", line.connection_type))
            rows.push(createData("Mac", line.mac_index))
            rows.push(createData("SSID 2.4", line.ssid_2_4GHz))
            rows.push(createData("SSID 5", line.ssid_5GHz))
        })
    }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" onClick={handleClickOpen}>
        Ver Topologia
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Topologia"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 550 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Titulo</TableCell>
                    <TableCell align="right">Configurado</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.titulo}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {row.titulo}
                    </TableCell>
                    <TableCell align="right">{row.param}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
