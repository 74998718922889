import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DhcpTable } from '../../../../../interfaces/DeviceMap';

export default function TableCpeInfo({ data }: { data : DhcpTable[] }) {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nome do Host</TableCell>
            <TableCell align="left">Endereço IP</TableCell>
            <TableCell align="left">Endereço Mac</TableCell>
            <TableCell align="left">Modo DHCP</TableCell>
            <TableCell align="left">Fabricante</TableCell>
            <TableCell align="left">Porta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.ip_addr}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.host_name}
              </TableCell>
              <TableCell align="left">{row.ip_addr}</TableCell>
              <TableCell align="left">{row.mac_addr}</TableCell>
              <TableCell align="left">{row.dhcp_mode}</TableCell>
              <TableCell align="left">{row.vendor}</TableCell>
              <TableCell align="left">{row.ethernet_port}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}