import { ComponentProps } from "react"

import styles from "./Footer.module.css";

interface FooterProps extends ComponentProps<"footer">{

}

const Footer = (props: FooterProps) => {
  return (
    <footer className={styles.footer} {...props} >
      <span>&copy;</span><p> MagicTool - Centro De Desenvolvimento De Dispositivos </p>
    </footer>
  );
};

export default Footer;