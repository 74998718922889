import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './contexts/UserContext';
import { StyledEngineProvider } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './config/keycloak';
import { GetCertProvider } from './hooks/useGetCertContext';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <ReactKeycloakProvider authClient={keycloak} >
    <React.StrictMode>
      <UserProvider>
        <StyledEngineProvider injectFirst>
          <GetCertProvider>
            <App />
          </GetCertProvider>
        </StyledEngineProvider>
      </UserProvider>
    </React.StrictMode >
  </ReactKeycloakProvider>
);
