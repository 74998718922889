// react 
import { useState, useEffect, useContext } from "react"

// css
import styles from "../NewHac.module.css"

//assets
import hgu from "../../../assets/cpes/1_geral.png"
import hguWifi6 from "../../../assets/cpes/Hgu6-Frente.png"

//interfaces
import { DadosAtividade } from "../../../interfaces/Hac"

// modules
import DateFormated from "../../../components/ReturnDate/DateFormated";
import { Box, CircularProgress } from "@mui/material";

import { GetCertContext } from "../../../hooks/useGetCertContext"
import { FileX2 } from "lucide-react"

const Aside = () => {

    const [view, setView] = useState(25)
    const [display, setDisplay] = useState("flex")
    const [dadosHGU, setDadosHGU] = useState<DadosAtividade | undefined>()

    const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6"];
const { loading, cert, errorMessage } = useContext(GetCertContext)
    

    const handleView = () => {
        if (view === 0) {
            setView(25)
            setDisplay("flex")
        } else {
            setView(0)
        }
    }

    useEffect(() => {
        if (cert) {
            setDadosHGU(cert.dadosAtividade)
        }
    }, [cert])

    return (
        <div
            className={styles.aside_view}
            style={{ transition: "1s", width: `${view}%` }}
        >
            {!loading ? <>
                <header>
                    <div
                        className={styles.aside_icon_close_aside}
                        onClick={handleView}
                    >
                        <span
                            className="material-symbols-outlined"
                            style={{
                                transform: view === 0 ? "rotate(90deg)" : "rotate(270deg)",
                                transition: "1s"
                            }}
                        >
                            arrow_drop_down
                        </span>
                    </div>
                    {dadosHGU ?
                        <span
                            className={styles.adise_connected_info}
                            style={{
                                display: `${display}`,
                                transform: view === 0 ? "translate(270px)" : "translate(0px)",
                                transition: "0.7s"
                            }}
                        >
                            <h2>{dadosHGU.connection_state}</h2>
                            <p>
                                <DateFormated
                                    date={dadosHGU.sent_time}
                                    dateGmt={3}
                                />
                            </p>
                        </span>
                        :
                        <span
                            className={styles.adise_connected_info}
                            style={{
                                display: `${display}`,
                                transform: view === 0 ? "translate(270px)" : "translate(0px)",
                                transition: "0.7s"
                            }}
                        >
                        </span>
                    }
                </header>
                <>
                    {dadosHGU &&
                        <main
                            style={{
                                transform: view === 0 ? "translate(270px)" : "translate(0px)",
                                transition: "0.7s",
                                display: view === 0 ? "none" : "block"
                            }}
                        >
                            <span className={styles.image_hgu}>
                                <img
                                    src={wifi6List.includes(dadosHGU.modelo) ? hguWifi6 : hgu}
                                    alt="HGU_image" />
                                <p>{dadosHGU.device_class}</p>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.modelo_alias}:</p>
                                <h1>{dadosHGU.modelo}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.designador_alias}:</p>
                                <h1>{dadosHGU.designador}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                {/* ajustar quando retornar o ipv4 do back */}
                                <p>IPv4:</p>
                                <h1>{dadosHGU.internet_ipv4}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.serial_number_alias}:</p>
                                <h1>{dadosHGU.serial_number}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.firmware_alias}:</p>
                                <h1>{dadosHGU.firmware}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.device_id_alias}:</p>
                                <h1>{dadosHGU.device_id}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.iothub_alias}:</p>
                                <h1>{dadosHGU.iothub}</h1>
                            </span>
                            <span className={styles.aside_span_info}>
                                <p>{dadosHGU.last_activity_time_alias}:</p>
                                <h1>
                                    <DateFormated
                                        date={dadosHGU.last_activity_time}
                                        dateGmt={3}
                                    />
                                </h1>
                            </span>
                        </main>
                    }
                    {errorMessage &&
                        <p
                            style={{
                                textAlign: "center",
                                padding: "20px 0",
                                fontWeight: "bold"
                            }}>
                            Não foi possível carregar as informações
                        </p>
                    }
                </>
                {!cert && <>
                    <div style={{
                            textAlign: "center",
                            height: "360px",
                            display: "flex",
                            flexDirection:"column",
                            justifyContent:"center"
                        }}>
                        <figure>
                            <FileX2 width="26px" height="26px" />
                        </figure>
                        <p style={{ padding: "4px" }}>Reestabeleça conexão com o cliente</p>
                    </div>
                </>}
            </>
                :
                <>
                    <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                </>}
        </div>
    )
}

export default Aside