//react
import { useState, useEffect } from "react";

// css
import styles from "./CoberturaWifi.module.css"

//assets
import ElipsedIcon from "../../../../assets/svgs/ElipsedIcon";

//modules
import  CoberturaChart  from "./CoberturaChart"

//external
import Checkbox from '@mui/material/Checkbox';
import { pink, blue } from '@mui/material/colors';
import { RefreshCcw, Table2Icon } from "lucide-react";

import TableDevices from "./TableDevices";
import { AliasCobertura } from "../../../../interfaces/ScoreInterface";

interface CoberturaProps {
  scoreStation: AliasCobertura | undefined
  scoreInterface: AliasCobertura | undefined
}

const CoberturaWifi = ({ scoreStation, scoreInterface } : CoberturaProps) => {

  const [score2G, setScore2G] = useState<number[]>([])
  const [score5G, setScore5G] = useState<number[]>([])
  const [date1, setDate1] = useState<Array<number | null>>([])
  const [date2, setDate2] = useState<Array<number | null>>([])
  const [week, setWeek] = useState<string[]>(["01/07", "02/07", "03/07", "04/07", "05/07", "06/07", "07/07", "08/07", "09/07" , "10/07"])
  const [check2g, setCheck2g] = useState(true)
  const [check5g, setCheck5g] = useState(true)
  const [tableView, setTableView] = useState(true)
  const [displayview, setDisplayView] = useState("")
  const [scoreStationData, setScoreStationData] = useState(scoreStation)
  const [scoreInterfaceData, setInterfaceData] = useState(scoreInterface)

  useEffect(()=>{
    if(tableView){
        setDisplayView("")
    }else{
      setTimeout(()=>{
        setDisplayView("none")
      }, 100)
    }
      
  }, [tableView])

  const orderData = () => {
    scoreInterfaceData?.values.sort((a, b) => {
      const dateA = new Date(a.score_date)
      const dateB = new Date(b.score_date)

      return dateA.getTime() - dateB.getTime()
    })

    const result = {
      "2.4 GHz": {
        score_date: [] as string[],
        avarega_scores: [] as number[],
        
      },
      "5 GHz": {
        score_date: [] as string[],
        avarega_scores: [] as number[],
      }
    } 

    scoreInterfaceData?.values.forEach(item => {
      const [year, month, day] = item.score_date.split("-")
      const formattedDate = `${day}/${month}`
      if(item.frequency.includes("2.4")){
        result["2.4 GHz"].score_date.push(formattedDate)
        result["2.4 GHz"].avarega_scores.push(item.weighted_average_score)
      }else if(item.frequency.includes("5")){
        result["5 GHz"].score_date.push(formattedDate)
        result["5 GHz"].avarega_scores.push(item.weighted_average_score)
      }
    })

    if(result["5 GHz"].score_date.length > result["2.4 GHz"].score_date.length){
      setWeek(result["5 GHz"].score_date)
    }else{
      setWeek(result["2.4 GHz"].score_date)
    }

    if(check2g){
      setDate1(result["2.4 GHz"].avarega_scores)
    }else{
      setDate1([null])
    }

    if(check5g){
      setDate2(result["5 GHz"].avarega_scores)
    }else{
      setDate2([null])
    }
  
  }

  useEffect(() => {

    if(scoreInterface){
      orderData()
    }

  },  [scoreInterface, check2g, check5g])

  return (
    <div className={styles.group}>
      <header className={styles.main}>
        <section className={styles.graphic_visualization}>
          <span className={styles.span_icon}>
            <Checkbox 
              checked={check2g}
              onChange={e => setCheck2g(e.target.checked)}
              sx={{
              color: pink[800],
              '&.Mui-checked': {
                color: pink[600],
              },
            }} />
            <div>
              <p>Cobertura 2.4GHz</p>
              <ElipsedIcon color="#FF0000" />
            </div>
          </span >
          <span className={styles.span_icon}>
            <Checkbox 
              checked={check5g}
              onChange={e => setCheck5g(e.target.checked)}
              sx={{
                color: blue[800],
                '&.Mui-checked': {
                  color: blue[900],
                },
              }} 
            />
            <div>
              <p>Cobertura 5GHz</p>
              <ElipsedIcon color="#5065DA" />
            </div>
          </span>
        </section>
        <section className={styles.atualization}>
          <span>
            <p>ultima atualização: 10/07/2024 às 10:24:23</p>
            <RefreshCcw />
          </span>
          <div className={styles.atualization_tabel}>
            <p>{tableView ? <>Ocultar Tabela</>: <>Ver Tabela</>}</p>
            <Table2Icon 
              onClick={tableView ? () => setTableView(false) : () => setTableView(true)} 
              cursor="pointer"
            />
          </div>
        </section>
      </header>
      <main className={styles.content}>
        <CoberturaChart 
          xData={week} 
          data={date1} 
          otherDate={date2} 
          width={tableView ? "50%" : "100%"} 
        />
        <div 
          style={{
            overflow: "hidden", 
            transform: tableView ? "translate(0)" : "translate(200%)",
            transition: "1.5s",
            display: displayview
          }}
        >
          {scoreStation && <TableDevices response={scoreStation} />}
        </ div>
      </main>
    </div>
  )
}

export default CoberturaWifi