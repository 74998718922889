//react 
import { useContext } from "react"

//context
import { GetCertContext } from "../../../../hooks/useGetCertContext"

//styles
import styles from "../Certification/Certification.module.css"

// icons
import { Check, ExternalLink, X } from "lucide-react"

//components
import AlertDialogSlide from "../../Details/ViewMore";

const Cards = () => {

    const { cert } = useContext(GetCertContext)

    return (
        <section className={styles.right}>
            {cert && <>


                <article>
                    <div>
                        <header className={styles.items_hgu}>
                            <h1>{cert?.summary_status.cert_lan.alias}</h1>
                            <AlertDialogSlide title={cert.summary_status.cert_lan.alias} />
                        </header>
                        <>
                            {cert?.summary_status.cert_lan.passed.map((item, index) => (
                                <span key={index} className={index % 2 === 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <Check color="#00BA13" />
                                </span>
                            ))}
                        </>
                        <>
                            {cert?.summary_status.cert_lan.failed.map((item, index) => (
                                <span key={index} className={index % 2 !== 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <X color="#FF0000" />
                                </span>
                            ))}
                        </>
                    </div>
                    <div>
                        <header className={styles.items_hgu}>
                            <h1>{cert?.summary_status.cert_voip.alias}</h1>
                            <AlertDialogSlide title={cert.summary_status.cert_voip.alias} />
                        </header>
                        <>
                            {cert?.summary_status.cert_voip.passed.map((item, index) => (
                                <span key={index} className={index % 2 === 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <Check color="#00BA13" />
                                </span>
                            ))}
                        </>
                        <>
                            {cert?.summary_status.cert_voip.failed.map((item, index) => (
                                <span key={index} className={index % 2 !== 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <X color="#FF0000" />
                                </span>
                            ))}
                        </>
                    </div>
                    <div>
                        <header className={styles.items_hgu}>
                            <h1>{cert?.summary_status.cert_wan.alias}</h1>
                            <AlertDialogSlide title={cert.summary_status.cert_wan.alias} />
                        </header>
                        <>
                            {cert?.summary_status.cert_wan.passed.map((item, index) => (
                                <span key={index} className={index % 2 === 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <Check color="#00BA13" />
                                </span>
                            ))}
                        </>
                        <>
                            {cert?.summary_status.cert_wan.failed.map((item, index) => (
                                <span key={index} className={index % 2 !== 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <X color="#FF0000" />
                                </span>
                            ))}
                        </>
                    </div>
                    {cert?.summary_status.cert_wifi_5G_BS && <>
                        <div>
                            <header className={styles.items_hgu}>
                                <h1>{cert?.summary_status.cert_wifi_5G_BS?.alias}</h1>
                                <AlertDialogSlide title={cert.summary_status.cert_wifi_5G_BS.alias} />
                            </header>
                            <>
                                {cert?.summary_status.cert_wifi_5G_BS.passed.map((item, index) => (
                                    <span key={index} className={index % 2 === 0 ? styles.items_hgu_color : styles.items_hgu}>
                                        <p>{item.alias}</p>
                                        <Check color="#00BA13" />
                                    </span>
                                ))}
                            </>
                            <>
                                {cert?.summary_status.cert_wifi_5G_BS.failed.map((item, index) => (
                                    <span key={index} className={index % 2 !== 0 ? styles.items_hgu_color : styles.items_hgu}>
                                        <p>{item.alias}</p>
                                        <X color="#FF0000" />
                                    </span>
                                ))}
                            </>
                        </div>
                    </>}
                </article>
                <article>
                    <div>
                        <header className={styles.items_hgu}>
                            <h1>{cert?.summary_status.cert_wifi_2G.alias}</h1>
                            <AlertDialogSlide title={cert.summary_status.cert_wifi_2G.alias} />
                        </header>
                        <>
                            {cert?.summary_status.cert_wifi_2G.passed.map((item, index) => (
                                <span key={index} className={index % 2 === 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <Check color="#00BA13" />
                                </span>
                            ))}
                        </>
                        <>
                            {cert?.summary_status.cert_wifi_2G.failed.map((item, index) => (
                                <span key={index} className={index % 2 !== 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <X color="#FF0000" />
                                </span>
                            ))}
                        </>
                    </div>
                    <div>
                        <header className={styles.items_hgu}>
                            <h1>{cert?.summary_status.cert_wifi_5G.alias}</h1>
                            <AlertDialogSlide title={cert.summary_status.cert_wifi_5G.alias} />
                        </header>
                        <>
                            {cert?.summary_status.cert_wifi_5G.passed.map((item, index) => (
                                <span key={index} className={index % 2 === 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <Check color="#00BA13" />
                                </span>
                            ))}
                        </>
                        <>
                            {cert?.summary_status.cert_wifi_5G.failed.map((item, index) => (
                                <span key={index} className={index % 2 !== 0 ? styles.items_hgu_color : styles.items_hgu}>
                                    <p>{item.alias}</p>
                                    <X color="#FF0000" />
                                </span>
                            ))}
                        </>
                    </div>
                </article>
            </>}
        </section>
    )
}

export default Cards