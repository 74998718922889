import { ReactNode, createContext, useEffect, useState } from "react"

//request
import { HacRequest } from "../services/apiHac";

// interfaces
import { HacInfoData } from "../interfaces/Hac";
import { DhcpTable } from "../interfaces/DeviceMap";

type CertContextProps = {
    children: ReactNode
}

type ResponseType = {
    cert: HacInfoData | undefined;
    loading: boolean;
    errorMessage: string; 
    getCert: (newCert: any) => void;
    setCert: (newCert: any) => void;
    setLoading: (loading: boolean) => void;
    dhcpTable: DhcpTable[] | undefined;
    setDhcpTable: (dhcpTable: any) => void;
}

const initialValue = {
    cert: undefined,
    loading: false,
    getCert: () => { },
    setCert: () => { },
    setLoading: () => { },
    errorMessage: '',
    dhcpTable: undefined,
    setDhcpTable: () => { }
}

export const GetCertContext = createContext<ResponseType>(initialValue);

export const GetCertProvider = ({ children }: CertContextProps) => {

    const [cert, setCert] = useState<HacInfoData | undefined>()
    const [loading, setLoading] = useState(initialValue.loading)
    const [errorMessage, setErrorMessage] = useState(initialValue.errorMessage)
    const [dhcpTable, setDhcpTable] = useState<DhcpTable[] | undefined>(initialValue.dhcpTable)

    const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6"];

    const getCert = async (id: string) => {
        setLoading(true)
        try {
            const res = await HacRequest(id)
            if (wifi6List.includes(res.dadosAtividade.modelo)) {
                delete res.summary_status.cert_wifi_5G_BS;
                delete res.cert_wifi_5G_BS;
            }
            setCert(res)
        } catch (error: any) {
            setErrorMessage(error.detail)
        } finally {
            setLoading(false)
        }
    }

    return (
        <GetCertContext.Provider
            value={{
                cert, loading, errorMessage, getCert, setLoading, setCert, dhcpTable, setDhcpTable
            }}>
            {children}
        </GetCertContext.Provider>
    )
}