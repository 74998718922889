import React from 'react'

const ElipsedIcon = ({color}: {color: string}) => {
    return (
        <svg width="35" height="16" viewBox="0 0 35 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8H34" stroke={color} stroke-width="2" stroke-linecap="round" />
            <circle cx="18" cy="8" r="7" fill="white" stroke={color} stroke-width="2" />
        </svg>

    )
}

export default ElipsedIcon