import styles from './MenuDropdown.module.css';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom'; // You'll need to import Link from your router library
import { IMenuItem } from '../interfaces/MenuItem';
import { useState } from 'react';

interface Props {
  displayName: string;
  menuItems: IMenuItem[];
  onMenuOpen?: () => void; // Callback function to handle Menu open
  onMenuClose?: () => void; // Callback function to handle Menu close
}

const MenuDropdown = ({ displayName, menuItems, onMenuOpen, onMenuClose }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (onMenuOpen) onMenuOpen(); // Call the callback function when the Menu is opened
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onMenuClose) onMenuClose();
  };

  return (
    <div>
      <Button
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ my: 2, color: 'inherit', display: 'block' }}
      >
        {displayName}
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.menu}
        // anchorReference='anchorPosition'
        // anchorPosition={{ top: 81, left: 110 }}
        slotProps={{ paper: { style: { borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' } } }}
      >
        {menuItems.map((categoryItem, index) => (
          <div key={index}>
            <MenuItem className={styles.menu_category} disabled sx={{
              "&.Mui-disabled": {
                opacity: 1
              }
            }} >{categoryItem.category}</MenuItem>
            {categoryItem.items.map((menuItem, itemIndex) => (
              <MenuItem
                className={styles.menu_item}
                key={itemIndex}
                onClick={handleClose}
                component={Link} to={menuItem.link}
              >
                <Typography>
                  {menuItem.text}
                  {menuItem.new && (
                    <span className={styles.new_item}>novo</span>
                  )}
                </Typography>
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default MenuDropdown;