import { Card } from '@mui/material'
import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

//css
import styles from "./DonnutChart.module.css"


interface Props {
    percent: number,
    test: number | undefined,
    name: string
}


const DonnutChart = ({ percent, test, name }: Props) => {
    const msgList: string[]  = ["Parâmetros configurados com sucesso", "Parâmetros configurados com alertas", "Parâmetros configurados com erros"]
  return (
        <Card className={styles.chart} variant="outlined" >
            <div className={styles.graphicContent}>
                <article className={styles.textContainer}>
                    <h1 className={styles.title} >{name}</h1>
                    <div className={styles.graphic}>
                        <CircularProgressbarWithChildren strokeWidth={11}  styles={buildStyles({trailColor: '#B9B9B9', pathColor: name === "Aprovados" ? "green" : name === "Alarmes" ? "orange" : "red"})} value={Math.round(percent)}>
                            <div className={styles.percentGraph} >
                                <strong className={styles.label_progress}>{Math.round(percent)}%</strong>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                </article>
                <span className={styles.texts}>
                    <p className={styles.textItens}><strong>{test}</strong>Itens</p>
                    {name === "Aprovados" && <p style={{marginBottom: "5px"}} className={styles.subtext}>{msgList[0]}</p>}
                    {name === "Alarmes" && <p style={{marginBottom: "5px"}} className={styles.subtext}>{msgList[1]}</p>}
                    {name === "Reprovados" && <p style={{marginBottom: "5px"}} className={styles.subtext}>{msgList[2]}</p>}
                </span>
            </div>
        </Card>
  )
}

export default DonnutChart