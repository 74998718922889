// react
import { useState } from "react"

// css
import styles from "./Percent.module.css"

type Props = {
  passed: number;
  failed: number
}

const Percent = ({passed, failed}: Props) => {

  const [total] = useState(passed+failed)

  const [percentPassed] = useState(Math.round((passed / total) * 100))
  const [percentFailed] = useState(Math.round((failed / total) * 100))
  const [percentAboutTwentyPassed] = useState(Math.round((percentPassed / 100 ) * 20))
  const [percentAboutTwentyFailed] = useState(Math.round((percentFailed / 100 ) * 20))

  return (
    <div className={styles.main}>
      <span className={styles.passed}>
        <div>
          <h1>Aprovados</h1>
          <p><p style={{display: "contents", fontWeight: "bold"}}>{passed}</p> Items</p>
        </div>
        <div className={styles.container_p}>
          {Array.from({ length: 20}).map((_, index) => (
            <>
              {index < percentAboutTwentyPassed ? 
                <p key={index} className={styles.bar_i_passed}></p>
                :
                <p key={index}></p>
              }
            </>
          ))}
        </div>
        <h1>{Math.round(percentPassed)} %</h1>
      </span>
      <span className={styles.failed}>
        <div>
          <h1>Reprovados</h1>
          <p><p style={{display: "contents", fontWeight: "bold"}}>{failed}</p> Items</p>
        </div>
        <div className={styles.container_p}>
          {Array.from({ length: 20}).map((_, index) => (
            <>
              { index < percentAboutTwentyFailed ?
                <p key={index}  className={styles.bar_i_failed_colored}></p>
                :
                <p key={index} ></p>
              }
            </>
          ))}
        </div>
        <h1>{Math.round(percentFailed)} %</h1>
      </span>
    </div>
  )
}

export default Percent