import { useParams } from "react-router-dom"

import styles from "./SubNavBar.module.css"

import { Home } from "lucide-react"
import SearchInput from "../Search/SearchInput"
import { useEffect } from "react"

const SubNavBar = () => {

    // vai vir uma url diferente
    let { filter, value, page } = useParams()

    useEffect(() => {
        
    }, [filter, value])

  return (
    <div className={styles.container}>
        <nav >
            {
                filter &&
                    <ul className={styles.list_nav}>
                        <li style={{cursor: "pointer"}}><Home /> /</li>
                        <li>Serviços e produtos /</li>
                        <li>
                            {filter.includes("remote") ? "MagicTool Remoto /"
                            : filter.includes('Wifi') ? "Certificado Wi-Fi /"
                            : filter.includes("BandWidth") && "Certificado Banda Larga /"

                            }
                        </li>
                        {value && 
                            <li>{value}</li>
                        }
                    </ul>
            }
            {
                page && page.includes("dash") && 
                <ul className={styles.list_nav}>
                    <li style={{cursor: "pointer"}}><Home /> /</li>
                    <li>Inicio</li>
                </ul>
            }
        </nav>
        <div className={styles.search_input}>
            <SearchInput />
        </div>
    </div>
  )
}

export default SubNavBar