import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Network from './TablesComponents/Network';
import Wifi5GPage from './TablesComponents/Wifi5GComponent';
import Wifi5GBSPage from './TablesComponents/Wifi5GBSComponent';
import Wifi2GPage from './TablesComponents/Wifi2GComponent';
import SmartHome from './TablesComponents/SmarHomeComponent';
import RedeLocal from './TablesComponents/LanComponent';
import VoIp from './TablesComponents/VoIpComponent';

// import AlertDialog from './Acordion';

import {Wan,
  Wifi5G, 
  Wifi5GBS,
  Wifi2G,
  WifiSmartHome,
  Lan,
  Voip,
  HacInfoData
} from '../../../interfaces/Hac';

import { HacRequest } from '../../../services/apiHac';
import { ExternalLink } from 'lucide-react';
import { GetCertContext } from '../../../hooks/useGetCertContext';
// import Hac from '../Hac';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function AlertDialogSlide({title} : {title: string}) {

  const { cert: data } = React.useContext(GetCertContext)

  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('md');
  
  const [cert, setCert] = React.useState<HacInfoData | undefined>(data)
  const [cert5G, setCert5G] = React.useState<Wifi5G >()
  const [cert5GBS, setCert5GBS] = React.useState<Wifi5GBS >()
  const [wifi2g, setWifi2g] = React.useState<Wifi2G >()
  const [smartHome, setSmartHome] = React.useState<WifiSmartHome >()
  const [lan, setLan] = React.useState<Lan>()
  const [network, setNetwork] = React.useState<Wan>()
  const [voIp, setVoIp] = React.useState<Voip>()

  const [load, setLoad] = React.useState(false)
  
  const setCertificado = async(data: HacInfoData) =>{
    setLoad(true)
    switch(title){
      case "Rede WAN":
        setNetwork(data.cert_wan)
        break
      
      case "Rede LAN":
        setLan(data.cert_lan)
        break
      
      case "VoIP":
        setVoIp(data.cert_voip)
        break
      
      case "WiFi 5 GHz BandSteering":
        setCert5GBS(data.cert_wifi_5G_BS)
        break
      
      case "WiFi 2 GHz":
        setWifi2g(data.cert_wifi_2G)
        break
      
      case "WiFi 5 GHz":
        setCert5G(data.cert_wifi_5G)
        break
    }
    setLoad(false)
    
  }

  React.useEffect(() => {
    if(cert){
      setCertificado(cert)
    }
 }, [])

  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ExternalLink onClick={handleClickOpen} cursor="pointer" />
      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{display: "flex", alignItems: "top", justifyContent: "space-between"}}>{`Detalhes ${title}`}
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-slide-description">
            {load ? <><Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Box></>: <>
              {title === "Rede WAN" && <Network dataRow={network} /> }
              {title === "WiFi 5 GHz" && <Wifi5GPage dataRow={cert5G} /> }
              {title === "WiFi 5 GHz BandSteering" && <Wifi5GBSPage dataRow={cert5GBS} /> }
              {title === "WiFi 2 GHz" && <Wifi2GPage dataRow={wifi2g} /> }
              {title === "Rede LAN" && <RedeLocal dataRow={lan} /> }
              {title === "VoIP" && <VoIp dataRow={voIp} /> }
            </>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
  }
