import { Card, CardContent } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import { Roles } from '../utils/EnumRoles';

type Props = {};



const Unauthorized = (props: Props) => {
  const navigate = useNavigate();
  const { userHasRole } = useUserContext();

  useEffect(() => {
    if (userHasRole(Object.values(Roles))) {
      navigate('/');
    }
  }, []);

  return (
    <div>
      <Card>
        <CardContent>
          <h1>Não Autorizado.</h1>
        </CardContent>
      </Card>
    </div>
  );
};

export default Unauthorized;