
type Props = {
    statusPort: string;
}

export const EntryRj45 = ({statusPort}: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            {statusPort === "up" ?
                <>
                    <path d="M0.482284 11.1567L12.8498 11.1567C12.9811 11.1567 13.0876 11.0502 13.0876 10.9189L13.0876 0.454072C13.0876 0.322548 12.9811 0.216235 12.8498 0.216235L0.482285 0.216234C0.35076 0.216234 0.244448 0.322547 0.244448 0.454071L0.244447 10.9189C0.244447 11.0502 0.35076 11.1567 0.482284 11.1567ZM0.720121 0.691908L12.612 0.691909L12.612 10.6811L0.72012 10.6811L0.720121 0.691908Z" fill="#00BA13" />
                    <path d="M10.9472 2.5945L8.33101 2.5945L8.33101 1.88099C8.33101 1.74947 8.22446 1.64315 8.09318 1.64315L5.23913 1.64315C5.10761 1.64315 5.0013 1.74947 5.0013 1.88099L5.0013 2.5945L2.38509 2.5945C2.25357 2.5945 2.14725 2.70082 2.14725 2.83234L2.14725 9.49178C2.14725 9.62306 2.25356 9.72961 2.38509 9.72961L10.9472 9.72961C11.0785 9.72961 11.1851 9.62306 11.1851 9.49178L11.1851 2.83234C11.1851 2.70082 11.0785 2.5945 10.9472 2.5945ZM10.7094 9.25394L9.75804 9.25394L9.75804 7.58908C9.75804 7.45779 9.65149 7.35124 9.5202 7.35124C9.38891 7.35124 9.28236 7.45779 9.28236 7.58908L9.28236 9.25394L8.33101 9.25394L8.33101 7.58908C8.33101 7.45779 8.22446 7.35124 8.09318 7.35124C7.96189 7.35124 7.85534 7.45779 7.85534 7.58908L7.85534 9.25394L6.90399 9.25394L6.90399 7.58908C6.90399 7.45779 6.79744 7.35124 6.66616 7.35124C6.53487 7.35124 6.42832 7.45779 6.42832 7.58908L6.42832 9.25394L5.47697 9.25394L5.47697 7.58908C5.47697 7.45779 5.37066 7.35124 5.23913 7.35124C5.10761 7.35124 5.0013 7.45779 5.0013 7.58908L5.0013 9.25394L4.04995 9.25394L4.04995 7.58908C4.04995 7.45779 3.94363 7.35124 3.81211 7.35124C3.68059 7.35124 3.57427 7.45779 3.57427 7.58908L3.57427 9.25394L2.62293 9.25394L2.62293 3.07018L5.23913 3.07018C5.37066 3.07018 5.47697 2.96386 5.47697 2.83234L5.47697 2.11883L7.85534 2.11883L7.85534 2.83234C7.85534 2.96386 7.96189 3.07018 8.09318 3.07018L10.7094 3.07018L10.7094 9.25394Z" fill="#00BA13" />
                </>
                :
                <>
                    <path d="M0.482284 11.1567L12.8498 11.1567C12.9811 11.1567 13.0876 11.0502 13.0876 10.9189L13.0876 0.454072C13.0876 0.322548 12.9811 0.216235 12.8498 0.216235L0.482285 0.216234C0.35076 0.216234 0.244448 0.322547 0.244448 0.454071L0.244447 10.9189C0.244447 11.0502 0.35076 11.1567 0.482284 11.1567ZM0.720121 0.691908L12.612 0.691909L12.612 10.6811L0.72012 10.6811L0.720121 0.691908Z" fill="#FF0000" />
                    <path d="M10.9472 2.5945L8.33101 2.5945L8.33101 1.88099C8.33101 1.74947 8.22446 1.64315 8.09318 1.64315L5.23913 1.64315C5.10761 1.64315 5.0013 1.74947 5.0013 1.88099L5.0013 2.5945L2.38509 2.5945C2.25357 2.5945 2.14725 2.70082 2.14725 2.83234L2.14725 9.49178C2.14725 9.62306 2.25356 9.72961 2.38509 9.72961L10.9472 9.72961C11.0785 9.72961 11.1851 9.62306 11.1851 9.49178L11.1851 2.83234C11.1851 2.70082 11.0785 2.5945 10.9472 2.5945ZM10.7094 9.25394L9.75804 9.25394L9.75804 7.58908C9.75804 7.45779 9.65149 7.35124 9.5202 7.35124C9.38891 7.35124 9.28236 7.45779 9.28236 7.58908L9.28236 9.25394L8.33101 9.25394L8.33101 7.58908C8.33101 7.45779 8.22446 7.35124 8.09318 7.35124C7.96189 7.35124 7.85534 7.45779 7.85534 7.58908L7.85534 9.25394L6.90399 9.25394L6.90399 7.58908C6.90399 7.45779 6.79744 7.35124 6.66616 7.35124C6.53487 7.35124 6.42832 7.45779 6.42832 7.58908L6.42832 9.25394L5.47697 9.25394L5.47697 7.58908C5.47697 7.45779 5.37066 7.35124 5.23913 7.35124C5.10761 7.35124 5.0013 7.45779 5.0013 7.58908L5.0013 9.25394L4.04995 9.25394L4.04995 7.58908C4.04995 7.45779 3.94363 7.35124 3.81211 7.35124C3.68059 7.35124 3.57427 7.45779 3.57427 7.58908L3.57427 9.25394L2.62293 9.25394L2.62293 3.07018L5.23913 3.07018C5.37066 3.07018 5.47697 2.96386 5.47697 2.83234L5.47697 2.11883L7.85534 2.11883L7.85534 2.83234C7.85534 2.96386 7.96189 3.07018 8.09318 3.07018L10.7094 3.07018L10.7094 9.25394Z" fill="#FF0000" />
                </>
            }
        </svg>
    )

}