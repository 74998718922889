// styles
import styles from "./Alerts.module.css"

// Hooks
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"

// external
import { Select, MenuItem, OutlinedInput, InputAdornment, Box, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import 'dayjs/locale/de';
import dayjs, { Dayjs } from 'dayjs';

//assets
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ExternalLink,
  RefreshCcw,
  Search
} from 'lucide-react';

//interface
import { AlertsInterfaceData } from "../../../../interfaces/AlertsInterface";
import { AlertsRequest } from "../../../../services/apiHac";

enum Categoria {
  allCategory = 'Categoria' as any,
  coverage = 'Coverage' as any,
}

enum SubCategory {
  allSubCategory = "Sub-Categoria" as any,
  coverage_s_bad = "COVERAGE_S_BAD" as any,
}

type TypeAlerts = {
  criticos: number;
  moderado: number;
  leve: number;
}

type Props = {
  alertsCoverage: AlertsInterfaceData | undefined
}

const Alerts = ({ alertsCoverage }: Props) => {

  const [category, setCategory] = useState<Categoria>(Categoria.allCategory);
  const [alerts, setAlerts] = useState<AlertsInterfaceData | undefined>(alertsCoverage)
  const [subCategory, setSubCategory] = useState<SubCategory>(SubCategory.allSubCategory);
  const [queryValue, setQueryValue] = useState<string>('');
  const [dateIsVisible, setDateIsVisible] = useState<boolean>(false)
  const [dateIsVisibleEnd, setDateIsVisibleEnd] = useState<boolean>(false)
  const [dateInit, setDateInit] = useState<Dayjs | null>(dayjs())
  const [initialDateFilter, setInitialDateFilter] = useState<string>()
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs())
  const [endDateFilter, setEndDateFilter] = useState<string>()
  const [page, setPage] = useState(1)
  const [filterAlerts, setFilterAlerts] = useState(alerts?.values)
  const [typeAlerts, setTypeAlerts] = useState<TypeAlerts>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  const { value } = useParams()

  const handleChangeDate = (newDate: Dayjs | null) => {
    if (newDate) {
      setDateInit(newDate)
      setInitialDateFilter(newDate.format("YYYY-MM-DD"))
    }
    setDateIsVisible(false)
  }

  const handleChangeDateEnd = (newDate: Dayjs | null) => {
    if (newDate) {
      setDateEnd(newDate)
      setEndDateFilter(newDate.format("YYYY-MM-DD"))
    }
    setDateIsVisibleEnd(false)
  }

  const dateFormated = (data: string, br?: boolean) => {

    const date = new Date(data)

    const day = String(date.getDate()).padStart(2, "0")
    const months = String(date.getMonth() + 1).padStart(2, "0")
    const year = String(date.getFullYear())

    if (br) {
      return `${day}/${months}/${year}`
    } else {
      return `${year}-${months}-${day}`
    }
  }

  const handleAlertsData = async () => {
    if (value) {
      setLoading(true)
      try {

        const res = await AlertsRequest(value)
        setAlerts(res)

      } catch (error: any) {
        setError(error.detail)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {

    if (queryValue) {
      setFilterAlerts(filterAlerts?.filter(filter => filter.detection_description.includes(queryValue)
        || filter.execution_algorithm.includes(queryValue)
        || filter.execution_interval.includes(queryValue)
      ))
    } else {
      if (alerts) {
        const alertsFormated = formatarDatas(alerts)
        const alertsFinally: AlertsInterfaceData = {
          alias: alerts.alias,
          values: alertsFormated
        }
        setFilterAlerts(alertsFinally.values)
      }
    }

  }, [queryValue])

  const formatarDatas = (alertsList: AlertsInterfaceData, br?: boolean) => {
    return alertsList.values.map(item => ({
      ...item, execution_time: dateFormated(item.execution_time, br)
    }))
  }

  useEffect(() => {
    if (alerts) {
      const alertsFormated = formatarDatas(alerts)
      const alertsFinally: AlertsInterfaceData = {
        alias: alerts.alias,
        values: alertsFormated
      }
      setAlerts(alertsFinally)
      setFilterAlerts(alertsFinally.values)
    }
  }, [])

  useEffect(() => {
    if (alertsCoverage) {
      let mapped = {
        moderado: 0,
        criticos: 0,
        leve: 0,
      }

      alertsCoverage.values.forEach(obj => {

        if (obj.detection_code === 1) {
          mapped.leve += 1;
        } else if (obj.detection_code === 2) {
          mapped.moderado += 1;
        } else if (obj.detection_code === 3 || obj.detection_code === 4) {
          mapped.criticos += 1;
        }
      })

      setTypeAlerts(mapped)
    }
  }, [])

  const totalPages = Math.ceil(filterAlerts ? filterAlerts.length / 5 : 0)

  function goBackPage() {
    setPage(page - 1)
  }

  function goNextPag() {
    setPage(page + 1)
  }

  function goLastPage() {
    setPage(totalPages)
  }

  function goFirstPage() {
    setPage(1)
  }

  const filterByInterval = () => {
    if (dateInit && dateEnd) {
      const dataInicio = new Date(dateInit.format("YYYY-MM-DD"))
      const dataFim = new Date(dateEnd.format("YYYY-MM-DD"))

      const objFiltered = alerts?.values.filter((obj) => {
        const dateObj = new Date(obj.execution_time)
        return dateObj >= dataInicio && dateObj <= dataFim
      })

      setFilterAlerts(objFiltered)
    }
  }

  return (
    <div>
      {alerts?.values.length !== 0}{
        <>
          <div className={styles.container_filters}>
            <Select
              className={styles.select_filters}
              autoWidth
              value={category}
              onChange={(e) => setCategory(e.target.value as Categoria)}
              sx={{ padding: "2px" }}
            >
              <MenuItem sx={{ width: "250px" }} value={Categoria.allCategory}>{Categoria.allCategory}</MenuItem>
              <MenuItem sx={{ width: "250px" }} value={Categoria.coverage}>{Categoria.coverage}</MenuItem>
            </Select>
            <Select
              className={styles.select_filters}
              autoWidth
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value as SubCategory)}
              sx={{ padding: "2px" }}
            >
              <MenuItem sx={{ width: "250px" }} value={SubCategory.allSubCategory}>{SubCategory.allSubCategory}</MenuItem>
              <MenuItem sx={{ width: "250px" }} value={SubCategory.coverage_s_bad}>{SubCategory.coverage_s_bad}</MenuItem>
            </Select>
            <div className={styles.select_filters_date} >
              <OutlinedInput
                className={styles.select_filters}
                placeholder='Período de:'
                value={initialDateFilter ? dateFormated(initialDateFilter, true) : initialDateFilter}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                    >
                      {
                        dateIsVisible ?
                          <span className="material-symbols-outlined" onClick={() => setDateIsVisible(false)}>
                            arrow_drop_up
                          </span>
                          :
                          <span className="material-symbols-outlined" onClick={() => setDateIsVisible(true)}>
                            arrow_drop_down
                          </span>
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {dateIsVisible &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    sx={{ left: "35%" }}
                    value={dateInit}
                    onChange={(newDate) => handleChangeDate(newDate)}
                    className={styles.calendar}
                  />
                </LocalizationProvider>
              }
            </div>
            <div className={styles.select_filters_date} >
              <OutlinedInput
                className={styles.select_filters}
                placeholder='Até:'
                value={endDateFilter ? dateFormated(endDateFilter, true) : endDateFilter}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                    >
                      {
                        dateIsVisibleEnd ?
                          <span className="material-symbols-outlined" onClick={() => setDateIsVisibleEnd(false)}>
                            arrow_drop_up
                          </span>
                          :
                          <span className="material-symbols-outlined" onClick={() => setDateIsVisibleEnd(true)}>
                            arrow_drop_down
                          </span>
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />
              {dateIsVisibleEnd &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    sx={{ left: "35%" }}
                    value={dateEnd}
                    onChange={(newDate) => handleChangeDateEnd(newDate)}
                    className={styles.calendar}
                  />
                </LocalizationProvider>
              }
            </div>
            <div className={styles.search_filter}>
              <OutlinedInput
                className={styles.select_filters}
                placeholder='Buscar'
              />
              <span className={styles.icon_search} onClick={filterByInterval}>
                <Search />
              </span>
            </div>
          </div>
          <section className={styles.info_details_alert}>
            <span className={styles.type_alerts}>
              <h1>{alerts?.values.length} Alertas: </h1>
              <div className={styles.alerts_red}>
                <p className={styles.color_red}></p>
                <p>Críticos({typeAlerts?.criticos})</p>
              </div>
              <div className={styles.alerts_yellow}>
                <p className={styles.color_yellow}></p>
                <p>Graves({typeAlerts?.moderado})</p>
              </div>
              <div className={styles.alerts_green}>
                <p className={styles.color_green}></p>
                <p>Moderados({typeAlerts?.leve})</p>
              </div>
            </span>
            <span className={styles.atualization}>
              <Button
                onClick={handleAlertsData}
                sx={{ height: "2.5rem" }}
                variant="outlined"
                endIcon={<RefreshCcw />}>
                Atualizar
              </Button>
            </span>
          </section>
          <div className={styles.container_table}>
            <table>
              <thead>
                <tr>
                  <th style={{ padding: "8px 3px" }}></th>
                  <th>{alerts?.alias.detection_code}</th>
                  <th>{alerts?.alias.detection_description}</th>
                  <th>{alerts?.alias.execution_interval}</th>
                  <th>{alerts?.alias.execution_time}</th>
                  <th >
                    <OutlinedInput
                      className={styles.select_filters}
                      placeholder='Buscar'
                      onChange={(e) => setQueryValue(e.target.value)}
                    />
                  </th>
                </tr>
              </thead>
              {!loading && <>
                <tbody>
                  {filterAlerts?.slice((page - 1) * 5, page * 5).map((alerta, index) => (
                    <tr key={index}>
                      <td
                        style={{ padding: "6px 3px" }}
                        className={
                          alerta.detection_code === 3 || alerta.detection_code === 4 ? styles.grave_line
                            : alerta.detection_code === 2 ? styles.moderated_line
                              : alerta.detection_code === 1 && styles.soft_line
                        }>
                      </td>
                      <td>{alerta.execution_algorithm}</td>
                      <td>{alerta.detection_description}</td>
                      <td>{alerta.execution_interval}</td>
                      <td>{dateFormated(alerta.execution_time, true)}</td>
                      <td style={{ textAlign: "end" }} aria-disabled>
                        <ExternalLink color="#b5b5b5" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>}
              <>
                {!loading && alerts?.values.length !== 0 && <>
                  <tfoot>
                    <td colSpan={6} style={{ textAlign: "right" }}>
                      <div className={styles.pagination}>
                        <span>Pagina {page} de {totalPages} </span>
                        <div>
                          <button className={styles.buttonPage} onClick={goFirstPage} disabled={page === 1}>
                            <ChevronsLeft />
                          </button>
                          <button className={styles.buttonPage} onClick={goBackPage} disabled={page === 1}>
                            <ChevronLeft />
                          </button>
                          <button className={styles.buttonPage} onClick={goNextPag} disabled={page === totalPages}>
                            <ChevronRight />
                          </button>
                          <button className={styles.buttonPage} onClick={goLastPage} disabled={page === totalPages}>
                            <ChevronsRight />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tfoot>
                </>}
              </>
            </table>
            {loading && <>
              <Box sx={{display: "flex", marginTop: "5em", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </>}
          </div>
        </>
      }
      {alerts?.values.length === 0 &&
        <>
          <h1 style={{ width: "100%", textAlign: "center" }}>Ops.</h1>
          <h4 style={{ width: "100%", textAlign: "center" }}>Não há nada aqui...</h4>
        </>}
    </div>
  )
}

export default Alerts