import { parseISO, format, subHours } from 'date-fns';
import { useState } from 'react';

type Props = {
    date: string,
    dateGmt?: number
}

const DateFormated = ({date, dateGmt}: Props) => {

    const data = parseISO(date);
    const dateGMT3 = subHours(data, dateGmt ? dateGmt : 0);
    const dataFormatada = format(dateGMT3, "dd/MM/yyyy 'às' HH:mm:ss");

  return (
    <>
        {dataFormatada}
    </>
  )
}

export default DateFormated