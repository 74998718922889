//styles
import styles from "./SearchPage.module.css"

//components
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import SearchList from "../../components/Search/SearchList"

//hooks
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"

import { Box, CircularProgress } from "@mui/material"

import { useContext } from "react"
import { GetCertContext } from "../../hooks/useGetCertContext"
import { WifiListRequest, InternetListRequest } from "../../services/api"
import { IpLightListData } from "../../interfaces/IPLightList"
import { HacRequest } from "../../services/apiHac"

const SearchPage = () => {

  const { filter, value } = useParams()

  const [data, setData] = useState<IpLightListData[]>()

  const { loading, setCert, setLoading } = useContext(GetCertContext)

  const handleCerts = async (value: string) => {
    const wifi6List: string[] = ["RTF8225VW", "2742GX4X5v6", "GPT-2742GX4X5v6"];
    switch (filter) {
      case "remoteMagic":
        setLoading(true)
        try {
          const res = await HacRequest(value)
          if (wifi6List.includes(res.dadosAtividade.modelo)) {
            delete res.summary_status.cert_wifi_5G_BS;
            delete res.cert_wifi_5G_BS;
          }
          setCert(res)
          let list = [{
            product: "Magic Remoto",
            firmware: res.dadosAtividade.firmware,
            modelo: res.dadosAtividade.modelo,
            designador: res.dadosAtividade.designador,
            serial_number: res.dadosAtividade.serial_number,
            sent_time: res.dadosAtividade.sent_time,
          }]
          setData(list)
        } catch (error: any) {
          console.log(error)
        } finally {
          setLoading(false)
        }
        break

      case "certWifi":
        setLoading(true)
        try {
          const res = await WifiListRequest("serial_number", value)
          let novaLista = res.map(objeto => {
            return { ...objeto, product: 'Certificado Wi-Fi' }; // Adiciona a nova chave/valor
          });
          setData(novaLista)
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
        break

      case "certBandWidth":
        setLoading(true)
        try {
          const res = await InternetListRequest("serial_number", value)
          let novaLista = res.map(objeto => {
            return { ...objeto, product: 'Banda Larga' }; // Adiciona a nova chave/valor
          });
          setData(novaLista)
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
        break
    }
  }

  useEffect(() => {
    if (value) {
      handleCerts(value)
    }
  }, [value, filter])

  return (
    <div className={styles.container}>
      <p className="spacing"></p>
      <Navbar />
      {!loading && <SearchList response={data} />}
      {loading &&
        <Box sx={{ display: 'flex', margin: "5em", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      }
      <hr style={{ margin: 0, width: "99%" }} />
      <Footer />
    </div>
  )
}

export default SearchPage