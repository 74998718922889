//react
import React, { useState, useEffect } from 'react'

//styles 
import styles from "./CoberturaWifi.module.css"

//external
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AliasCobertura } from '../../../../interfaces/ScoreInterface';

type DeviceTableProps = {
    date: string;
    vendor: string;
    mac: string;
    relat: number
    scores: number
}

interface Props {
    response: AliasCobertura;
}

function createData(
    date: string,
    vendor: string | undefined,
    mac: string | undefined,
    relat: number,
    scores: number,
) {
  return { date, vendor, mac, relat, scores };
}

export default function TableDevices({response}: Props) {

    const rows = response.values.map((date) => (
        createData(
            date.score_date,
            date.vendor,
            date.mac_address,
            date.total_count,
            date.weighted_average_score,
        )   
    ))

    const dateFormated = (date: string) => {
      const [dia, mes, ano] = date.split("-")

      return `${dia}/${mes}/${ano}`

    }
  return (
    <TableContainer component={Paper} sx={{height: "300px", width: "100%"}}>
      <Table aria-label="simple table" >
        <TableHead>
          <TableRow>
            <TableCell align='center' sx={{padding: "8px"}}>DATA</TableCell>
            <TableCell align="center" sx={{padding: "8px"}}>FABRICANTE</TableCell>
            <TableCell align="center" sx={{padding: "8px"}}>MAC ADDR</TableCell>
            <TableCell align="center" sx={{padding: "8px"}}># RELAT.</TableCell>
            <TableCell align="center" sx={{padding: "8px"}}>SCORES <br /> COBERTURA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{padding: "8px"}}>
                {dateFormated(row.date)}
              </TableCell>
              <TableCell align="center" sx={{padding: "8px"}}>{row.vendor}</TableCell>
              <TableCell align="center" sx={{padding: "8px"}}>{row.mac}</TableCell>
              <TableCell align="center" sx={{padding: "8px"}}>{row.relat}</TableCell>
              <TableCell align="center" sx={{padding: "8px"}}>{row.scores}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
