//styles
import styles from "./MapDisp.module.css"

//icons
import { Server, Smartphone, Tv, Wifi } from "lucide-react";

//react
import { useEffect } from "react"

//interfaces
import { DhcpTable } from "../../../../interfaces/DeviceMap";


interface DipsProps {
    devices: DhcpTable[]
}

const CardDisp = ( { devices } : DipsProps) => {

    const vendorListSmartPhone:string[] = [
        "motorola", "Motorola", "moto", "SM", "sm", "Mobility", "lg", "LG", "Lg",
        "mobile", "Mobile", "Samsung", "Galaxy", "M52"
    ]

    const vendorListUnknow:string[] = [
        "Intelbras", "intelbras", "TP-LINK", "DESKTOP", "Chromecast", "ARRIS", "D-Link",
        "HPNA", "Amazon", "SJI"
    ]

    const vendorListTV:string[] = [
        "Samsung-TV", "tv", "Tv"
    ]

    useEffect(() => {
    }, [devices]);

    return (
        <div className={styles.container_devices} >
            {devices.map((disp, index) => (
                <div key={index} className={styles.div_info}>
                    <span>
                        <Wifi color='#00BA13' style={{ width: "30px", height: "30px" }} />
                    </span>
                    <ul>
                        <li>
                            <p className={styles.title}>{disp.radio}</p>
                            <p>{disp.rssi} RSSI</p>
                        </li>
                        <li>
                            <p className={styles.title}>IP</p>
                            <p>{disp.ip_addr}</p>
                        </li>
                        <li>
                            <p className={styles.title}>Mac</p>
                            <p>{disp.mac_addr}</p>
                        </li>
                    </ul>
                    <div className={styles.div_icon}>
                        {vendorListSmartPhone.includes(disp.host_name) ?
                            <Smartphone style={{ width: "40px", height: "40px" }} />
                            : vendorListTV.includes(disp.host_name) ?
                                <Tv style={{ width: "40px", height: "40px" }} />
                                :
                                <Server style={{ width: "40px", height: "40px" }} />
                        }
                        <p className={styles.title}>{disp.host_name}</p>
                    </div>
                </div>
            ))}
            {devices.length === 0 && <>
                <h3>Nenhum dispositivo conectado</h3>
            </>}
        </div>
    )
}

export default CardDisp