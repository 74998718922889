import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Stack from '@mui/material/Stack';
import {
  lineElementClasses,
  markElementClasses,
} from '@mui/x-charts/LineChart';


import styles from "./CoberturaWifi.module.css"

interface Props {
  xData: string[],
  data: Array<number | null>,
  otherDate: Array<number | null>,
  width: string
}


export default function CSSCustomization({xData, data, otherDate, width}:Props) {
  return (
    <Stack  direction="column" spacing={1} sx={{width: width }}>
      <LineChart
        sx={{
          [`& .${lineElementClasses.root}`]: {
            strokeWidth: 4,
          },
          '& .MuiAreaElement-series-Info1': {
            fill: "url('#myGradient')",
            strokeWidth: 3,
          },
          '& .MuiAreaElement-series-Info2': {
            fill: "url('#myGradient2')",
            strokeWidth: 5,
          },
          [`& .${markElementClasses.root}`]: {
            // stroke: colorStroke,
            scale: '2',
            strokeWidth: 2,
          },
        }}
        xAxis={[
          {
            id: 'Weeks',
            data: xData,
            scaleType: 'point',
          },
        
        ]}
        yAxis={[
          {
            max: 10,
          }
        ]}
        series={[
          {
            id: 'Info1',
            data: data,
            area: true,
            color: "#FF0000",
            showMark: true,
          },
          {
            id: 'Info2',
            data: otherDate,
            color: "#5065DA",
            area: true,
            showMark: true,
          },
        ]}
        margin={{ top: 20, right: 20 }}
        height={300}
      >
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="rgba(255, 12, 117, 0.5)"  className={styles.path} />
            <stop offset="53%" stopColor="rgba(255, 104, 122, 0.5)" className={styles.path} />
            <stop offset="100%" stopColor="rgba(255, 195, 127, 0.5)"  className={styles.path}/>
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="myGradient2" gradientTransform="rotate(90)" >
            <stop offset="0%" stopColor="rgba(255, 0, 180, 0.5)"  className={styles.path}/>
            <stop offset="45%" stopColor="rgba(127, 78, 193, 0.5)" className={styles.path} />
            <stop offset="100%" stopColor="rgba(0, 155, 205, 0.5)"  className={styles.path}/>
          </linearGradient>
        </defs>
      </LineChart>
    </Stack>
  );
}