import React from 'react';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box, CssBaseline, Divider, ListItemButton, Toolbar } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { Roles } from '../utils/EnumRoles';
import { useUserContext } from '../contexts/UserContext';

const drawerWidth = 240;

type DrawerItens = {
  text: string;
  path: string;
  icon: any;
  allowedRoles?: string[];
};

const drawerItens: DrawerItens[] = [{ text: "Aprovar Usuários", path: "/approv-users", icon: ListAltOutlinedIcon, allowedRoles: [Roles.admin, Roles.avancado, Roles.suporte] }];


const SuporteSidebar = () => {
  const { userHasRole } = useUserContext();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {drawerItens.map((drawer, index) =>
              (!drawer.allowedRoles || userHasRole(drawer.allowedRoles)) && (
                <ListItem key={index} disablePadding>
                  <ListItemButton component={Link} to={"approv-users"}>
                    <ListItemIcon>
                      {drawer.icon && <drawer.icon />}
                    </ListItemIcon>
                    <ListItemText primary={drawer.text} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, height: "100dvh", backgroundColor: "#ffff" }}>
        {/* <Toolbar /> */}
        {/* Render subroutes of /suporte */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default SuporteSidebar;
