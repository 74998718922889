import styles from './App.module.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
// Context
// Hooks
// Utils
import { Roles } from './utils/EnumRoles';

// Components
import ProtectedRoute from './components/ProtectedRoute';

// Pages
import Home from './pages/Home/Home';
import Suporte from './pages/Suporte';
import ApprovUser from './pages/ApproveUser/ApprovUser';
import Unauthorized from './pages/Unauthorized';
import NotFound from './pages/NotFound';
import NewWifi from './pages/WiFi/NewWifi';
import Internet from './pages/Internet/Internet';
import SearchPage from './pages/Search/SearchPage';

import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress } from '@mui/material';
import DashInitit from './pages/dash/DashInitit';
import NewHac from './pages/Hac/NewHac';

function App() {

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      }
    }
  }, [initialized]);

  if (!initialized || !keycloak.authenticated) {
    return (
      <div className={styles.app_loading}>
        <h1>MagicTool</h1>
        <CircularProgress />
      </div>
    );
  }


  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <div className={styles.App}>
        <Routes>
          {/* Public Routes */}
          <Route path='/unauthorized' element={<Unauthorized />} />

          {/* Protected Routes */}
          <Route element={<ProtectedRoute allowedRoles={Object.values(Roles)} />}>
            <Route path='/' element={<Home />} />
            {/* <Route path='/:page' element={<DashInitit />} /> */}
            <Route path='/servicos-produtos/:filter/:value?' element={<SearchPage />} />
            <Route path='/servicos-produtos/banda-larga/:filter/:value' element={<Internet />} />
            <Route path='/servicos-produtos/wi-fi/:filter/:value' element={<NewWifi />} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={[Roles.admin, Roles.avancado, Roles.suporte]} />}>
            <Route path='/servicos-produtos/hac/:filter/:value' element={<NewHac />} />
            <Route path='/suporte' element={<Suporte />}  >
              <Route path='approv-users' element={<ApprovUser />} />
            </Route>
          </Route>

          {/* Not Found */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
